import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				The Copper Stein
			</title>
			<meta name={"description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:title"} content={"The Copper Stein"} />
			<meta property={"og:description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65773594fdcae00021dadf60/images/close-up-man-pouring-coffee-glass%20%281%29.jpg?v=2023-12-11T18:26:16.280Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
		</Helmet>
		<Components.Header1 />
		<Section padding="80px 0 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				EventExcellence'e Hoş Geldiniz
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				EventExcellence hakkında daha fazla bilgi edinin, ekibimizi tanıyın ve sunduğumuz hizmetleri keşfedin. Etkinlik planlama ve yönetimi konusunda neden tercih edilen bir seçim olduğumuzu keşfedin.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Hero-19">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-overflow-y="hidden"
				lg-overflow-x="hidden"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-padding="0 50px 50px 50px"
					padding="0px 80px 0px 80px"
					sm-padding="0 15px 50px 15px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						align-items="center"
						sm-align-items="flex-start"
					>
						<Text
							margin="0px 30px 0px 0px"
							color="--light"
							font="--headline3"
							sm-margin="0px 0 15px 0px"
							background="--color-lightD2"
							padding="5px 15px 5px 15px"
							border-radius="15px"
							border-color="--color-light"
						>
							Hikayemiz
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Etkinlik Mükemmelliği
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base" color="--dark" lg-margin="0px 0px 30px 0px">
						EventExcellence, kalıcı bir etki bırakan olağanüstü etkinlikler yaratma amacıyla kuruldu. Küçük bir ekiple başlayan büyük bir hayal, bugün kalite, yaratıcılık ve müşteri memnuniyeti konusundaki taahhüdümüzle tanınan tam hizmet sunan bir etkinlik ajansına dönüştü.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Vizyonumuz ve Misyonumuz
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base" color="--dark" lg-margin="0px 0px 30px 0px">
						Misyonumuz, olağanüstü etkinlik planlama ve yönetimiyle unutulmaz deneyimler yaratmaktır. Her etkinliğin, ne kadar büyük ya da küçük olursa olsun, tam dikkat ve yaratıcılığı hak ettiğine inanıyoruz.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Hizmetlerimiz
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base" color="--dark" lg-margin="0px 0px 30px 0px">
						EventExcellence'de, tüm etkinlik planlama ihtiyaçlarınızı karşılamak için kapsamlı hizmetler sunuyoruz. İster kurumsal bir etkinlik, ister bir düğün veya özel bir kutlama planlıyor olun, vizyonunuzu hayata geçirmek için gerekli uzmanlığa ve kaynaklara sahibiz.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					object-position="0 0"
					padding="15px 0px 15px 15px"
					border-radius="25px 0 0 25px"
					background="--color-lightD1"
					sm-margin="0px 0px 0px 15px"
					lg-margin="0px 0px 0px 25px"
					srcSet="https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10%3A25%3A49.524Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10%3A25%3A49.524Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10%3A25%3A49.524Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10%3A25%3A49.524Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10%3A25%3A49.524Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10%3A25%3A49.524Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10%3A25%3A49.524Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD1" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10:25:49.515Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10%3A25%3A49.515Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10%3A25%3A49.515Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10%3A25%3A49.515Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10%3A25%3A49.515Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10%3A25%3A49.515Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10%3A25%3A49.515Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-2.jpg?v=2024-08-01T10%3A25%3A49.515Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--primary"
						>
							Ekibimizle Tanışın
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Alanında Uzmanlar
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							EventExcellence’in gücü ekibimizden gelir. Her bir üyemiz, etkinliklerin başarılı olmasına katkı sağlayan farklı becerilere ve uzmanlığa sahiptir.
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Yenilikçi Çözümler
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							Her zaman yeni fikirler ve teknolojiler keşfederek etkinliğinizi unutulmaz kılmak için çalışıyoruz.
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Müşteri Memnuniyeti
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							EventExcellence ile çalışarak, etkinliğinizin sorunsuz geçmesini ve beklentilerinizi aşmasını sağlayacak uzmanlarla işbirliği yapmış olursunuz.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Hero-19">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-overflow-y="hidden"
				lg-overflow-x="hidden"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-padding="0 50px 50px 50px"
					padding="0px 80px 0px 80px"
					sm-padding="0 15px 50px 15px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						align-items="center"
						sm-align-items="flex-start"
					>
						<Text
							margin="0px 30px 0px 0px"
							color="--light"
							font="--headline3"
							sm-margin="0px 0 15px 0px"
							background="--color-lightD2"
							padding="5px 15px 5px 15px"
							border-radius="15px"
						>
							Öne Çıkan Özelliklerimiz
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Müşteri Odaklı Yaklaşım
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark" lg-margin="0px 0px 30px 0px">
						İhtiyaçlarınızı ve tercihlerinizi ön planda tutarak, her kararın sizin çıkarlarınız doğrultusunda alınmasını sağlıyoruz.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Detaylara Dikkat
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark" lg-margin="0px 0px 30px 0px">
						Etkinliğinizin her yönünün titizlikle planlanmasını ve uygulanmasını sağlıyoruz.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Yaratıcı Mükemmellik
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark" lg-margin="0px 0px 30px 0px">
						Yaratıcılık ve yenilikçiliğe olan tutkumuz, etkinliğinizin benzersiz ve akılda kalıcı olmasını sağlar.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10:25:49.513Z"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					object-position="0 0"
					padding="15px 0px 15px 15px"
					border-radius="25px 0 0 25px"
					background="--color-lightD1"
					sm-margin="0px 0px 0px 15px"
					lg-margin="0px 0px 0px 25px"
					srcSet="https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10%3A25%3A49.513Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10%3A25%3A49.513Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10%3A25%3A49.513Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10%3A25%3A49.513Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10%3A25%3A49.513Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10%3A25%3A49.513Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-3.jpg?v=2024-08-01T10%3A25%3A49.513Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD1" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1595066358810-9ec693361025?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--primary"
						>
							İletişim Bilgileri
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							En Kısa Sürede Geri Dönüş
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							Bize telefon, e-posta veya bu sayfadaki iletişim formunu doldurarak ulaşabilirsiniz. Tüm sorulara 24 saat içinde yanıt vermeyi hedefliyoruz.
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Unutulmaz Etkinlikler
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							EventExcellence olarak, unutulmaz etkinlikler yaratmanıza yardımcı olmak için buradayız. İhtiyaçlarınızı tartışmak ve vizyonunuzu gerçeğe dönüştürmek için bugün bizimle iletişime geçin.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.NewFooter1 />
		<Components.Terms />
	</Theme>;
});